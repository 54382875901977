import './scripts/common.js';
import Vue from 'vue';
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
import store from './store/store';
import Services from './scripts/services/services';

import BootstrapVue from 'bootstrap-vue';
//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import '@fortawesome/fontawesome-free/js/all.min.js';
import breakpoints from '@eli5/bootstrap-breakpoints-vue';
import moment from 'moment';
import './images/personal.png';

import VueLodash from 'vue-lodash';
import lodash from 'lodash';

Vue.use(BootstrapVue);
Vue.use(breakpoints);
Vue.use(VueLodash, { lodash: lodash })

// Boot the current Vue component
const root = document.getElementById('app');

store.commit('layout', JSON.parse(root.dataset.layout));
store.commit('env', JSON.parse(root.dataset.env));
store.commit('title', root.dataset.title);
store.commit('routes', JSON.parse(root.dataset.routes));
store.commit('menu', JSON.parse(root.dataset.menu));

Services.instance().register('store', () => {
    return store;
});

Services.instance().register('event', () => {
    return new Vue();
});

moment.locale('ru');
Vue.mixin({
    methods: {
        pluralForm(n, forms) {
            return n % 10 == 1 && n % 100 != 11
                ? forms[0]
                : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
                    ? forms[1]
                    : forms[2];
        },
        changeModal(modal, flag) {
            this.$store.commit('modals', {modal, flag});
        },
        route(name) {
            return '/' + this.$store.state.routes[name].replace(/^\//, '');
        },
        datetimePrint(date) {
            return moment(date, "YYYY-MM-DD HH:mm:ss").format('LLL');
        },
        dateCalendarPrint(date) {
            return moment(date, "YYYY-MM-DD").calendar(undefined, {
                sameDay: '[Сегодня]',
                nextDay: '[Завтра]',
                lastDay: '[Вчера]',
                nextWeek: function (now) {
                    if (now.week() !== this.week()) {
                        switch (this.day()) {
                            case 0:
                                return '[В следующее] dddd';
                            case 1:
                            case 2:
                            case 4:
                                return '[В следующий] dddd';
                            case 3:
                            case 5:
                            case 6:
                                return '[В следующую] dddd';
                        }
                    } else {
                        if (this.day() === 2) {
                            return '[Во] dddd';
                        } else {
                            return '[В] dddd';
                        }
                    }
                },
                lastWeek: function (now) {
                    if (now.week() !== this.week()) {
                        switch (this.day()) {
                            case 0:
                                return '[В прошлое] dddd';
                            case 1:
                            case 2:
                            case 4:
                                return '[В прошлый] dddd';
                            case 3:
                            case 5:
                            case 6:
                                return '[В прошлую] dddd';
                        }
                    } else {
                        if (this.day() === 2) {
                            return '[Во] dddd';
                        } else {
                            return '[В] dddd';
                        }
                    }
                },
                sameElse: function (now) {
                    if (now.year() === this.year()) {
                        return 'D MMMM';
                    } else {
                        return 'LL';
                    }
                }
            });
        },
        timePrint(date) {
            return moment(date, "YYYY-MM-DD HH:mm:ss").format('HH:mm');
        },
        datePrint(date, skipYear = false) {
            let dateMoment = moment(date, "YYYY-MM-DD");
            let format = 'LL';
            if (skipYear) {
                if (dateMoment.year() === moment().year()) {
                    format = 'D MMMM';
                }
            }
            return dateMoment.format(format);
        },
        phonePrint(str) {
            //Filter only numbers from the input
            let cleaned = ('' + str).replace(/\D/g, '');

            //Check if the input is of correct length
            let match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);

            if (match) {
                return '+' + match[1] + '(' + match[2] + ') ' + match[3] + '-' + match[4] + '-' + match[5];
            }

            return '';
        },
        storage_url(url) {
            return `/storage/${url}`;
        },
        avatar_url(url) {
            return url ? this.storage_url(url) : '/assets/images/personal.png';
        },
        tg_url(url) {
            return `https://t.me/${url}`;
        },
    },
    computed: {
        /** @returns {User} */
        user() {
            return this.$store.state.layout.user;
        },
        themeDark() {
            return this.$store.state.layout.themeDark;
        },
        /** @returns {EgorkaRequestStatus} */
        egorkaRequestStatus() {
            return this.$store.state.layout.egorkaRequestStatus;
        },
        egorkaRequestStatusName() {
            return this.$store.state.layout.egorkaRequestStatusName;
        },
        /** @returns {WorkMode} */
        workMode() {
            return this.$store.state.layout.workMode;
        },
        workModeName() {
            return this.$store.state.layout.workModeName;
        },
    },
});

/**
 @typedef User
 @type {Object}
 @property {boolean} isAuth - isAuth
 @property {number} id - id
 @property {string} avatar - avatar
 @property {boolean} isAdmin - isAdmin
 @property {boolean} isRR - isRR
 */
/**
 @typedef EgorkaRequestStatus
 @type {Object}
 @property {number} new - new
 @property {number} payed - payed
 @property {number} reject - reject
 */
/**
 @typedef WorkMode
 @type {Object}
 @property {number} office - office
 @property {number} home - home
 @property {number} mix - mix
 */

