<template>
    <b-navbar :type="themeDark ? 'dark' : 'light'" toggleable="md" v-if="user.isAuth">
        <div class="container container-content">
            <!-- Переключалка дропдауна меню показывается в мобильном виде -->
            <b-navbar-toggle target="nav-collapse"/>

            <!-- Бренд в десктопе будет самым первым, а в мобилке после переключалки -->
            <b-navbar-brand href="/">
                <img src="../../../images/logo.svg" class="navbar-brand-img"/>
            </b-navbar-brand>

            <!-- Основной блок с меню в десктопе по центру, а на мобилке выпадающее -->
            <b-collapse id="nav-collapse" is-nav style="flex-grow: 1;" class="mx-lg-4 mx-md-2">
                <b-navbar-nav>
                    <b-nav-item v-for="item in menu"
                        :href="route(item.route)"
                        :active="item.active"
                        :key="item.route"
                    >
                        {{ item.name }}
                    </b-nav-item>

                    <!-- Т.к. в мобильном виде скрывается профиль, то эти пункты показываются тут -->
                    <b-nav-item :href="route('profile')" class="d-md-none">Профиль</b-nav-item>
                    <b-nav-item :href="route('logout')" class="d-md-none">Выйти</b-nav-item>
                </b-navbar-nav>
            </b-collapse>

            <!-- Профиль показывается на десктопном виде в шапке справа -->
            <div class="navbar-user d-none d-md-block">
                <div class="dropdown">
                    <a class="avatar avatar-sm">
                        <img :src="avatar_url(user.avatar)" class="avatar-img rounded-circle"/>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <b-dropdown-item :href="route('profile')">Профиль</b-dropdown-item>
                        <b-dropdown-divider/>
                        <b-dropdown-item :href="route('logout')">Выйти</b-dropdown-item>
                    </div>
                </div>
            </div>
        </div>
    </b-navbar>
</template>

<script>

export default {
    name: 'layout-part-menu-top',
    computed: {
        menu() {
            return this.$store.state.menu.top;
        },
    }
};
</script>

<style scoped>

</style>