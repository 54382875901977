var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user.isAuth
    ? _c(
        "b-navbar",
        { attrs: { type: _vm.themeDark ? "dark" : "light", toggleable: "md" } },
        [
          _c(
            "div",
            { staticClass: "container container-content" },
            [
              _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
              _vm._v(" "),
              _c("b-navbar-brand", { attrs: { href: "/" } }, [
                _c("img", {
                  staticClass: "navbar-brand-img",
                  attrs: { src: require("../../../images/logo.svg") }
                })
              ]),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  staticClass: "mx-lg-4 mx-md-2",
                  staticStyle: { "flex-grow": "1" },
                  attrs: { id: "nav-collapse", "is-nav": "" }
                },
                [
                  _c(
                    "b-navbar-nav",
                    [
                      _vm._l(_vm.menu, function(item) {
                        return _c(
                          "b-nav-item",
                          {
                            key: item.route,
                            attrs: {
                              href: _vm.route(item.route),
                              active: item.active
                            }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(item.name) +
                                "\n                "
                            )
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "b-nav-item",
                        {
                          staticClass: "d-md-none",
                          attrs: { href: _vm.route("profile") }
                        },
                        [_vm._v("Профиль")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-nav-item",
                        {
                          staticClass: "d-md-none",
                          attrs: { href: _vm.route("logout") }
                        },
                        [_vm._v("Выйти")]
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "navbar-user d-none d-md-block" }, [
                _c("div", { staticClass: "dropdown" }, [
                  _c("a", { staticClass: "avatar avatar-sm" }, [
                    _c("img", {
                      staticClass: "avatar-img rounded-circle",
                      attrs: { src: _vm.avatar_url(_vm.user.avatar) }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dropdown-menu dropdown-menu-right" },
                    [
                      _c(
                        "b-dropdown-item",
                        { attrs: { href: _vm.route("profile") } },
                        [_vm._v("Профиль")]
                      ),
                      _vm._v(" "),
                      _c("b-dropdown-divider"),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        { attrs: { href: _vm.route("logout") } },
                        [_vm._v("Выйти")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }