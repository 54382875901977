<template>
    <div class="fake-vue-body">
        <layout-part-menu-top/>
        <div class="main-content">

            <div class="container p-4 container-content" :style="!noFill ? 'background: #f6f8ff;' : ''">
                <div class="d-md-flex justify-content-between" v-if="!noTitle">
                    <div>
                        <slot name="title" :title-text="titleText">
                            <h1 class="d-inline">{{ titleText }}</h1>
                            <slot name="title-addition"/>
                        </slot>
                    </div>
                    <div>
                        <slot name="title-right"/>
                    </div>
                </div>
                <layout-part-menu-sub/>
                <slot/>
            </div>
        </div>

        <div id="preloader" v-show="loaderShow"><div id="loader"></div></div>
    </div>
</template>

<script>
import LayoutPartMenuTop from './part/menu-top.vue';
import LayoutPartMenuSub from './part/menu-sub.vue';
import Services from '../../scripts/services/services.js';
import 'animate.css';

export default {
    name: 'layout',
    props: {
        noTitle: {
            type: Boolean,
            default: false,
        },
        noFill: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        LayoutPartMenuTop,
        LayoutPartMenuSub,
    },
    computed: {
        loaderShow() {
            return Services.store().state.loaderShow;
        },
        titleText() {
            return Services.store().state.title;
        },
    },
    created() {
        Services.event().$on('toast', ({text, variant}) => {
            this.$bvToast.toast(text, {
                title: 'Сообщение',
                variant: variant || 'success',
                //toaster: 'b-toaster-top-center',
                solid: true,
                autoHideDelay: 5000,
            });
        });
    }
};
</script>

<style scoped>
    .fake-vue-body {
        height: 100%;
    }

    /* ============= Loader ===================== */
    #preloader {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        background: rgba(255, 255, 255, 0.6);
    }
    #loader {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #000000;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    #loader:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #000000;
        -webkit-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
    }
    #loader:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #000000;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }
    @-webkit-keyframes spin {
        0%   {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes spin {
        0%   {
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>
