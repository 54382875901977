<template>
    <b-nav tabs v-if="menu.length" class="mb-4">
        <b-nav-item v-for="item in menu" :active="item.active" :href="route(item.route)" :key="item.route">{{ item.name }}</b-nav-item>
    </b-nav>
</template>

<script>

export default {
    name: 'layout-part-menu-sub',
    components: {
    },
    computed: {
        menu() {
            return this.$store.state.menu.sub;
        },
    }
};
</script>

<style scoped>

</style>