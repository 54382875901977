var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fake-vue-body" },
    [
      _c("layout-part-menu-top"),
      _vm._v(" "),
      _c("div", { staticClass: "main-content" }, [
        _c(
          "div",
          {
            staticClass: "container p-4 container-content",
            style: !_vm.noFill ? "background: #f6f8ff;" : ""
          },
          [
            !_vm.noTitle
              ? _c(
                  "div",
                  { staticClass: "d-md-flex justify-content-between" },
                  [
                    _c(
                      "div",
                      [
                        _vm._t(
                          "title",
                          [
                            _c("h1", { staticClass: "d-inline" }, [
                              _vm._v(_vm._s(_vm.titleText))
                            ]),
                            _vm._v(" "),
                            _vm._t("title-addition")
                          ],
                          { titleText: _vm.titleText }
                        )
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", [_vm._t("title-right")], 2)
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("layout-part-menu-sub"),
            _vm._v(" "),
            _vm._t("default")
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loaderShow,
              expression: "loaderShow"
            }
          ],
          attrs: { id: "preloader" }
        },
        [_c("div", { attrs: { id: "loader" } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }