import Vue from 'vue';
import Layout from '../components/layout/layout.vue';
import '../styles/common.js';

import '../images/logo.svg';
import '../images/sprite/rotny.svg';
import '../images/sprite/work-mode-office.svg';
import '../images/sprite/work-mode-home.svg';
import '../images/sprite/work-mode-mix.svg';

Vue.component('v-layout', Layout);

