var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.menu.length
    ? _c(
        "b-nav",
        { staticClass: "mb-4", attrs: { tabs: "" } },
        _vm._l(_vm.menu, function(item) {
          return _c(
            "b-nav-item",
            {
              key: item.route,
              attrs: { active: item.active, href: _vm.route(item.route) }
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }